.alj{
	color: #888888;
	font-size: 0.75em;
}
.header-link{
	display: none;
}
.full-size-img{
	margin-top: 1.0em;
	margin-bottom: 2.0em;
}
.clear { clear: both; }
.iphone-size-img {
	max-width:500px;
	margin-bottom: 2.0em;
}
.img{
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	-webkit-filter: drop-shadow(2px 2px 4px rgba(0,0,0,0.5));
    -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=2, OffY=2, Color='#444')";
    filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=2, OffY=2, Color='#444')";
}

img.submit-list{
	width: 100px;		
}

.yase-tribe{
	background: #FFE978;
}

.ua-tribe{
	background: #A2DC70;
}

.luvu-tribe{
	background: #66D0EE;
}	

.list-group-item{
	border: 1px solid #aaaaaa;
}

@media only screen and (max-width: 640px) {
    .iphone-size-img {
		margin-top: 1.0em;
		margin-bottom: 2.0em;
		width:100%;
	}

}
